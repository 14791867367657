import Head from "next/head";
import CollatsBrief from "../components/CollatsBrief";
import ContactUs from "../components/contactUs/ContactUs";
import CallToAction from "../components/home/CallToAction";
import Dao from "../components/home/Dao";
import FAQ from "../components/home/FAQ";
import Hero from "../components/home/Hero";
import Instructions from "../components/home/Instructions";
import IntroVideo from "../components/home/IntroVideo";
import Roadmap from "../components/home/Roadmap";
import TopTen from "../components/home/TopTen";
import MediumPosts from "../components/posts/MediumPosts";
import WarningAnnouncement from "../components/WarningAnnouncement";
// import styles from "../styles/Home.module.css";

//TODO: error: Abort fetching component for route.
//I clicked on Collection and immediately before it finished loading I clicked on Swap

//TODO: useERC20Balances to fetch balances of collats contract and its backup assets.
//TODO: useApiContract to execute a on-chain contract's function. It calls Moralis API and doesn't require web3 to be enabled.
//https://github.com/MoralisWeb3/react-moralis#useweb3executefunction

//TODO: Sync TopTen with the backend
export default function Home() {
  return (
    <>
      <WarningAnnouncement />
      <Hero />
      <IntroVideo />
      {/* <TopTen /> */}
      <Instructions />
      <CollatsBrief />
      <Dao />
      <Roadmap />
      {/* <Stats /> */}
      <FAQ />
      <MediumPosts />
      <ContactUs />
      <CallToAction />
    </>
  );
}
