import React from "react";

function WarningAnnouncement() {
  return (
    <div className="px-4 py-3 text-white bg-indigo-600">
      <p className="text-sm font-medium text-center">
        Collats is in beta!{" "}
        <a className="underline" href="https://discord.gg/hbM5bkyBaa">
          For Progress Info
        </a>
      </p>
    </div>
  );
}

export default WarningAnnouncement;
