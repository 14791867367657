import React, { useState, useEffect } from "react";
import PostGallery from "./PostGallery";
import axios from "axios";

const RSS2JSON_API =
  "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@collats";

const MediumPosts = ({ component: Component }) => {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await axios.get(RSS2JSON_API);

        const sortedItems = res.data.items
          .map((item) => ({ ...item, date: new Date(item.pubDate) }))
          //   .sort((a, b) => a.date - b.date);
          .sort((a, b) => b.date - a.date);

        setPosts(sortedItems);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) return <p className="text-center text-lg">Loading...</p>;
  if (error) return <div />;
  // return (
  //   <p className="text-center text-lg text-red-500">Error: {error.message}</p>
  // );
  return (
    <div className="w-full flex flex-col justify-start bg-slate-50 pb-10">
      <div className="flex w-full mt-10 mb-5 pl-8 justify-start">
        <h1 className="text-4xl font-medium text-gray-700">Latest Posts</h1>
      </div>

      <PostGallery posts={posts} />
    </div>
  );
};

export default MediumPosts;
