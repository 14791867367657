import React from "react";
import PostCard from "./PostCard";

const PostGallery = ({ posts }) => {
  return (
    <div className="overflow-x-auto flex flex-row">
      {posts.map((post) => (
        <PostCard key={post.guid} post={post} />
      ))}
    </div>
  );
};

export default PostGallery;
