import React from "react";

//How can I see the balance of the back up assets

function FAQ() {
  const faqs = [
    {
      q: "Where can I use Collats?",
      a: "Use Collats everywhere you want to use a token that is backed by more than faith and goodwill.",
    },
    {
      q: "Are collats stable coins?",
      a: "We don't think so. But Collats are fully backed one-to-one to US Dollar Pegged Stable Coins.",
    },
    {
      q: "What is the maximum circulation of collats?",
      a: "There is no cap. Collats are minted on the spot by the same amount of the stable coin being stored. When Collats are redeem, the same amount of stable coin is returned and those Collats are immediately burned.",
    },
    {
      q: "Where are the backup stable coins stored?",
      a: "All the backup tokens are stored in-chain and everyone can monitor its balance at any time.",
    },
    {
      q: "What stable coins back Collats",
      a: "Click here to see the whole list.",
    },
    {
      q: "What are the fees?",
      a: "There is no fee to redeem your Collats. There is a 0.9% fee to mint.",
    },
    {
      q: "In which chains are Collats available?",
      a: "Ethereum, Polygon and Binance Smart Chain.",
    },
    {
      q: "Is Collats Privately Owned?",
      a: "Collats is owned by its DAO. Join now!",
    },
  ];

  return (
    <div className="space-y-1 sm:mx-10  pt-4 pb-5 sm:pb-16 px-4 sm:px-16 rounded-lg">
      <div className="flex flex-wrap w-full mb-2 justify-center">
        <h1 className="sm:text-5xl text-4xl font-medium m-6 text-center">
          Frequently Asked Questions
        </h1>
      </div>
      {faqs.map((faq) => (
        <details
          key={faq.q}
          className="p-6 border-l-4 border-blue-500 bg-gray-50 group"
        >
          <summary className="flex items-center justify-between cursor-pointer">
            <h5 className="flex-1 text-lg font-medium text-gray-900 text-start">
              {faq.q}
            </h5>

            <span className="flex-shrink-0 ml-1.5 p-1.5 text-gray-900 bg-white rounded-full sm:p-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 w-5 h-5 transition duration-300 group-open:-rotate-45"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </summary>

          <p className="ml-2 mr-11 mt-4 leading-relaxed text-gray-700 font-light">
            {faq.a}
          </p>
        </details>
      ))}
    </div>
  );
}

export default FAQ;
