import Link from "next/link";
import React from "react";

function Hero() {
  return (
    <section className="text-white bg-gray-900 h-[80vh] lg:h-[85vh] items-center justify-center flex">
      <div className="max-w-screen-xl px-4 mx-auto mb-24 ">
        <div className="max-w-3xl mx-auto text-center">
          <h1 className="text-3xl font-extrabold text-transparent sm:text-5xl bg-clip-text bg-gradient-to-r from-green-300 via-blue-500 to-purple-600">
            Right now NFTs are increasing their value with Collats
          </h1>

          <p className="max-w-xl mx-auto mt-4 sm:leading-relaxed sm:text-xl">
            Add value to your NFTs now!
          </p>

          <div className="flex flex-wrap justify-center gap-4 mt-8">
            <Link href="/swap">
              <a className="block px-12 py-3 text-sm font-medium text-white bg-blue-600 border border-blue-600 rounded sm:w-auto active:text-opacity-75 hover:bg-transparent hover:text-white focus:outline-none focus:ring">
                Get Collats
              </a>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Hero;
