import Link from "next/link";
import React from "react";

function Dao() {
  return (
    <div className="flex justify-center text-gray-600 body-font">
      <div className="max-w-7xl  ">
        <div className="container mx-auto flex px-5 pt-32 md:flex-row flex-col items-center">
          <div className="flex md:hidden w-4/6 mb-20">
            <img
              className="object-cover object-center rounded"
              alt="hero"
              src="/images/dao.svg"
            ></img>
          </div>
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
              Governed by the community
            </h1>
            <p className="mb-4 leading-relaxed">
              Collats is governed by the Collats DAO Governance Token (CDGT)
              which purpose is to allocate decentralized ownership of the
              algorithms and its profits. Collats is owned by the Collats DAO
              Liquidity (CDLT) holders.
            </p>

            <p className="mb-4 leading-relaxed">
              {
                "The CDGT allow holders to have the ultimate saying in the governance of the Collats DAO and its finances. CDGT holders don't have a direct claim to the assets of the Collats DAO but are the ones that can vote for actions that should be beneficial to all the members of the Collats DAO."
              }
            </p>

            <div className="flex flex-wrap justify-center">
              <a
                href="https://docs.collats.com/tokenomics/collats-dao-liquidity-token"
                target="_blank"
                rel="noopener noreferrer"
                className="block px-12 py-3 text-sm font-medium text-white bg-blue-600 border border-blue-600 rounded sm:w-auto active:text-opacity-75 hover:bg-transparent hover:text-blue-600 focus:outline-none focus:ring"
              >
                Get CDLT
              </a>
            </div>
          </div>
          <div className="hidden md:flex lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
            <img
              className="object-cover object-center rounded"
              alt="hero"
              src="/images/dao.svg"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dao;
