import React from "react";

function Roadmap() {
  return (
    <div className="flex justify-center ">
      <div className="max-w-7xl  ">
        <div className="container px-5 py-40 mx-auto">
          <div className="flex flex-wrap w-full mb-10 justify-center">
            <h1 className="sm:text-5xl text-4xl font-medium mb-2">Roadmap</h1>
          </div>
          <div className="grid grid-cols-1 gap-2 mx-3 mt-4 sm:grid-cols-2 xl:grid-cols-4 justify-center  w-full">
            <div className="p-6">
              <img
                className=" rounded w-full object-center object-contain mb-6"
                src="/images/roadmap_q3.svg"
                alt="content"
              ></img>
            </div>
            <div className="p-6">
              <img
                className=" rounded w-full object-center object-contain mb-6"
                src="/images/roadmap_q4.svg"
                alt="content"
              ></img>
            </div>

            <div className="p-6">
              <img
                className=" rounded w-full object-center object-contain mb-6"
                src="/images/roadmap_q1.svg"
                alt="content"
              ></img>
            </div>

            <div className="p-6">
              <img
                className=" rounded w-full object-center object-contain mb-6"
                src="/images/roadmap_q2.svg"
                alt="content"
              ></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Roadmap;
