import React, { useState } from "react";
import StatusModal from "../details/StatusModal";
import FormInput from "./FormInput";
import MessageSentModal from "./MessageSentModal";
import SocialRow from "./SocialRow";
import ReCAPTCHA from "react-google-recaptcha";

function ContactUs() {
  const recaptchaRef = React.createRef();
  const [isSending, setIsSending] = useState(false);
  const [messageSentSuccessfully, setMessageSentSuccessfully] = useState(true);
  const [showMessageSentModal, setShowMessageSentModal] = useState(false);
  const [values, setValues] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const inputs = [
    {
      id: 1,
      name: "name",
      type: "text",
      placeholder: "Name",
      label: "Name",
      //   errorMessage:
      //     "Name should be 3-30 characters and should not include special characters",
      //   pattern: "^[A-Za-z0-9 ]{3,30}$",
      required: true,
      minLength: 3,
      maxLength: 30,
    },
    {
      id: 2,
      name: "email",
      type: "email",
      placeholder: "Email",
      label: "Email",
      //   errorMessage: "Enter a valid email",
      required: true,
      maxLength: 200,
    },
    {
      id: 3,
      name: "subject",
      type: "text",
      placeholder: "Subject",
      label: "Subject",
      //   errorMessage: ". ",
      maxLength: 100,
    },
    {
      id: 4,
      name: "message",
      type: "text",
      placeholder: "Message",
      label: "Message",
      //   errorMessage: "Enter your message",
      required: true,
      //TODO: L3:P2 minLength here is not being respected
      minLength: 3,
      maxLength: 1000,
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSending(true);
    // Execute the reCAPTCHA when the form is submitted
    recaptchaRef.current.execute();
  };

  const onReCAPTCHAChange = async (captchaCode) => {
    /*
        TODO L2:P2: Reset
        Doing this resets but the error messages are triggered.
        setValues({
        name: "",
        email: "",
        subject: "",
        message: "",
        });
    */

    try {
      // If the reCAPTCHA code is null or undefined indicating that
      // the reCAPTCHA was expired then return early
      if (!captchaCode) {
        setIsSending(false);
        setMessageSentSuccessfully(false);
        setShowMessageSentModal(true);
        return;
      }
      values.captchaCode = captchaCode;

      const res = await fetch("/api/contact", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(values),
      });

      setIsSending(false);

      if (res.ok) {
        setMessageSentSuccessfully(true);
      } else {
        setMessageSentSuccessfully(false);
      }
    } catch (error) {
      setIsSending(false);
      setMessageSentSuccessfully(false);
    } finally {
      setShowMessageSentModal(true);
      // Reset the reCAPTCHA so that it can be executed again if user
      // submits another email.
      recaptchaRef.current.reset();

      setValues({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
    }
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleShowMessageSentModal = async () => {
    setShowMessageSentModal(!showMessageSentModal);
  };

  return (
    <div className="w-full flex flex-col items-center justify-center  pb-20 bg-slate-50">
      <div className="text-5xl  font-medium mt-12 text-gray-700 text-center">
        Contact Us
      </div>
      <div className="text-lg text-gray-400 text-center">
        Have a question or recommendation?
      </div>
      <div className="text-lg mb-4 text-gray-400 text-center">Let us know!</div>
      <div className="w-5/6 md:w-4/5 lg:w-3/5 xl:w-2/5 flex flex-col items-center justify-center pt-10 px-10 pb-6 bg-white rounded-lg drop-shadow-lg space-y-4 ">
        <SocialRow />
        <form className="w-full" onSubmit={handleSubmit}>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
            onChange={onReCAPTCHAChange}
            hidden={true}
          />
          {inputs.map((input) => (
            <FormInput
              key={input.id}
              {...input}
              value={values[input.name]}
              onChange={onChange}
            />
          ))}
          <div className="text-gray-400 text-sm">
            This site is protected by reCAPTCHA and the Google{" "}
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
            <a href="https://policies.google.com/terms">Terms of Service</a>{" "}
            apply.
          </div>
          <div className="w-full flex items-center justify-center">
            <button
              //   className="w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-400 hover:bg-gray-600 mt-8"
              className=" bg-transparent hover:bg-gray-500 text-gray-500 font-medium hover:text-white py-2  px-6 border border-gray-500 hover:border-transparent rounded-sm mt-6"
            >
              Send
            </button>
          </div>
        </form>
      </div>

      {showMessageSentModal && (
        <MessageSentModal
          success={messageSentSuccessfully}
          handleShowModal={handleShowMessageSentModal}
        />
      )}

      {isSending && <StatusModal status="Sending..." />}
    </div>
  );
}

export default ContactUs;
