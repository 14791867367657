import Link from "next/link";
import React from "react";

export default function PostCard({ post }) {
  return (
    <Link href={post.guid}>
      <div
        key={post.guid}
        className="w-1/2 md:w-1/3 bg-white mx-4 my-2 flex-shrink-0 drop-shadow-lg rounded-2xl cursor-pointer"
      >
        <div key={post.guid} className="col-span-1 flex flex-col items-center">
          <div className="aspect-auto">
            <img
              src={post.thumbnail}
              alt={post.title}
              className="w-full h-full object-center object-cover rounded-t-2xl"
            />
          </div>
          <div className="px-2 pb-1 flex flex-col items-start w-full ">
            <h2 className="text-lg font-bold mt-2">{post.title}</h2>
            {/* <p className="text-sm text-gray-600">{post.subtitle}</p> */}
          </div>
          <div className="flex w-full mb-6 ml-8">
            <div className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-2xl text-sm px-3 py-2 text-center inline-flex items-center  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              Read more
              <svg
                className="-mr-1 ml-2 h-4 w-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
